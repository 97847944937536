import {
  COUNTRIES_DATA,
  EUROZONE_COUNTRIES,
  INTEGER_PRICE_CURRENCIES,
  TEN_ROUNDED_CURRENCIES
} from '@web-solutions/core/constants/currencies';

import { Currency } from '@web-solutions/react-billing/constants';

export const processingPrices = (price: string, geo: string, currency: Currency): string => {
  const actualCurrencyData = getActualCurrencyData(currency, geo);
  const { currency_symbol: symbol, currency_code: currencyCode, exchange_rate: rate } = actualCurrencyData || {};

  if (EUROZONE_COUNTRIES.includes(geo) && currency === "EUR") {
    return price;
  }

  if (rate && symbol && currencyCode && currencyCode !== "USD" && price) {
    return convertPrice(price, rate, currencyCode, symbol);
  }

  return price;
};

const convertPrice = (value: string, rate: number, currencyCode: string, symbol: string): string => {
  const numberString = parseFloat(value.replace(/,/g, ".").replace(/[^0-9.]/g, ""));
  let localPrice = numberString * rate;
  const integerPart = rate.toString().match(/^[+-]?\d+/)![0].length;

  if (localPrice !== 0) {
    localPrice = Math.floor(localPrice) + 0.99;
  }

  // Округление до целого
  if (INTEGER_PRICE_CURRENCIES.includes(currencyCode) || integerPart >= 3) {
    localPrice = Math.ceil(localPrice);
  }

  // Округление до ближайшего десятка
  if (TEN_ROUNDED_CURRENCIES.includes(currencyCode)) {
    localPrice = Math.ceil(localPrice / 10) * 10;
  }

  return formatPrice(localPrice, symbol, currencyCode);
};

const formatPrice = (localPrice: number, symbol: string, currencyCode: string): string => {
  let priceString = localPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' '); //  с двумя знаками после запятой и разделителем тысяч пробелами

  if (localPrice % 1 === 0) {
    priceString = priceString.split('.')[0];
  }

  if (symbol === "$") {
    return `${symbol}${priceString} ${currencyCode}`;
  }

  return `${symbol}${priceString}`;
};

export const getActualCurrencyData = (currency: string, countryCode: string) => {
  const countryData = COUNTRIES_DATA[currency as keyof typeof COUNTRIES_DATA] || '';
  const currencyData = countryData[countryCode as keyof typeof COUNTRIES_DATA];
  return currencyData;
}