import { useEffect, useState, useMemo } from 'react';

import { getBirthChart } from '@wowmaking/birthchart';

import { type PersonalizedData } from 'src/store/profile';

const birthChartCache = new Map();

export const useBirthChartData = (personalizedData: PersonalizedData | null) => {
  const { birth_date, location, birth_time } = personalizedData || {};

  const { year, month, day } = birth_date || {};
  const { lat, lon } = location || {};
  const { hour, minutes } = birth_time || {};

  const birthChartParams = useMemo(() => year && month && day && lat != null && lon != null ?
    ({
      date: `${year}-${month}-${day}`,
      time: `${hour || '12'}:${minutes || '00'}`,
      location: { lat: +lat, lon: +lon },
    }) :
    null,
    [year, month, day, hour, minutes, lat, lon])

  const [data, setData] = useState<{ planetsWithSignsAndHouses: { house: number, sign: string, name: string }[], houses: any, planets: any, zodiacByDate: string } | null>(() => birthChartCache.get(JSON.stringify(birthChartParams) ?? null));

  useEffect(() => {
    const getBirthChartData = async () => {

      if (!birth_date || !location) {
        setData(null);
        return;
      }

      if (birthChartParams) {
        try {
          const birthChartParamsString = JSON.stringify(birthChartParams)

          const cachedBirthChart = birthChartCache.get(birthChartParamsString)

          if (cachedBirthChart) {
            setData(cachedBirthChart);
            return
          }

          const res = await getBirthChart({
            birth: birthChartParams,
          });

          if (res.success) {
            const { planetsWithSignsAndHouses, natalHouses, natalPlanets, zodiacByDate } = res.data;

            birthChartCache.set(birthChartParamsString, { planetsWithSignsAndHouses, houses: natalHouses, planets: natalPlanets, zodiacByDate })

            setData({ planetsWithSignsAndHouses, houses: natalHouses, planets: natalPlanets, zodiacByDate });
          }
        } catch (error) {
          console.error("Error fetching birth chart data:", error);
        }
      }
    };

    getBirthChartData();
  }, [birth_date, location, birth_time, birthChartParams]);

  return data;
};
