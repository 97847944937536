import { useRef, useCallback } from "react"

import { checkIsBodyFixed } from "@web-solutions/core/utils/check-is-body-fixed"

export const useGetScrollToBottomHandler = () => {
  const rootElem = useRef(checkIsBodyFixed() ? document.getElementById('root') : document.body)

  const scrollToBottom = useCallback(() => {
    requestAnimationFrame(() => {
      if (rootElem.current) {
        rootElem.current.scrollTo({ top: rootElem.current.scrollHeight, behavior: 'smooth' })
      }
    })
  }, [])

  return scrollToBottom
}