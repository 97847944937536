export async function process(imgUrl, { linePointsAmount } = {}) {
  const formData = new FormData();

  formData.append('file', imgUrl.replace('data:image/jpeg;base64,', ''));

  formData.append('linePointsAmount', linePointsAmount);

  formData.append('accuracy', 0.5);

  const controller = new AbortController();

  setTimeout(() => {
    controller.abort(Error('signal timed out'));
  }, 16000);

  return fetch(`https://hand-detection.astroline.today/lines/`, {
    method: 'POST',
    body: formData,
    signal: controller.signal,
  })
    .then(r => {
      if (r.ok) {
        return r.json();
      }
      if (r.status === 400) { // hand is not detected
        return {};
      }
      throw Error(r.status + ' ' + r.statusText);
    })
    .then(r => {
      // ругаемся на правую руку и на левую тыльной стороной
      if (r?.is_flipped || r?.hand_points?.[4]?.x > r?.hand_points?.[0]?.x) {
        return {};
      }
      return r
    });

}

export function dist(p, ref) {
  return Math.sqrt((p.x - ref.x) ** 2 + (p.y - ref.y) ** 2);
}
