import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, } from 'react-router';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';

import { Title } from '@web-solutions/core/ui-elements';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import {
  savePalmData,
  setHandImg
} from '../../../store/actions';

import {
  selectImg,
  selectLines,
  selectImgDims,
  selectKeyPoints,
  selectBasePoint,
  selectPalmReport,
  selectPalmWithLines,
} from '../../../store/selectors';

import { LoadMethod } from '../../constants';

import { PalmReadingApproveFooter } from '../approve/footer';

import Lines from './lines';

import { makePalmScreenshotWithLines } from './screenshot';

import classes from './style.module.scss';

const tKey = 'palm_reading.QUIZ_PALM_READING_ANALYZING';

interface PalmReadingAnalyzingProps {
  onFinish: () => void;
  onDone: (withoutDelay?: boolean) => void;
  onRetakeClick: (type: LoadMethod) => void;
  isChatFlow?: boolean;
  magicDurationLoad: number;
}

const PalmReadingAnalyzing: React.FC<PalmReadingAnalyzingProps> = ({
  onFinish,
  onDone,
  onRetakeClick,
  isChatFlow,
  magicDurationLoad,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const finalProgressStep = 100;

  const imgRef = useRef<HTMLDivElement>(null);

  const { theme } = useRemoteConfig();
  const palmReport = useSelector(selectPalmReport);

  const pace = magicDurationLoad / finalProgressStep;

  const img = useSelector(selectImg);
  const lines = useSelector(selectLines);
  const imgDims = useSelector(selectImgDims);
  const keypoints = useSelector(selectKeyPoints);
  const basePoint = useSelector(selectBasePoint);
  const imgWithLines = useSelector(selectPalmWithLines);

  const dataFetched = !!lines || !!keypoints;
  const dataDefected = Object.keys(lines || {}).length < 3 || !keypoints || basePoint?.y > imgDims?.height * imgDims?.ratio * 1.05;

  const [progress, setProgress] = useState(0);

  const stages = [
    { step: 0, maxStep: 24, title: t(`${tKey}.progress.analyzing`) },
    { step: 25, maxStep: 49, title: t(`${tKey}.progress.scanning`) },
    { step: 50, maxStep: 74, title: t(`${tKey}.progress.identifying`) },
    { step: 75, maxStep: 100, title: t(`${tKey}.progress.generating`) },
  ];

  const updatePercentage = useCallback(() => {
    let timer = setTimeout(() => {
      setProgress(progress + 1);
    }, pace);
    return () => clearTimeout(timer);
  }, [progress, pace]);

  const savePalmWithLines = useCallback(async () => {
    if (imgRef.current) {
      const screenshot = imgWithLines || await makePalmScreenshotWithLines(imgRef.current);
      dispatch(savePalmData(screenshot, palmReport?.left_hand_dots));
      dispatch(setHandImg({ img: screenshot, keypoints, basePoint, lines, ...(imgDims || {}) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [palmReport, dispatch])

  useEffect(() => {
    if (dataFetched) {
      if (progress < finalProgressStep) {
        updatePercentage();
      } else {
        savePalmWithLines();
        onFinish();
        if (!dataDefected) {
          onDone();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetched, progress, dataDefected, updatePercentage, onDone, onFinish]);

  useEffect(() => {
    if (progress === 100 && dataDefected) {
      Analytics.trackEvent('quiz_palm_reading_analyzing', 'defected');
    }
  }, [dataDefected, progress])

  const handleDone = () => {
    onDone(true);
  }

  const handleRetakeClick = () => {
    Analytics.trackEvent('quiz_palm_reading_analyzing', 'retake');
    onRetakeClick(location.state?.type);
  }

  return (
    <div className={classNames(classes.wrapper, { [classes.chatflow]: isChatFlow })}>
      <div ref={imgRef} className={classes.container}>
        <img src={img} className={classes.photo} alt="palm" />
        <Lines progress={progress} />
      </div>
      <div className={classes.progress__wrapper}>
        {
          (theme?.mode === 'default' || !theme.mode) &&
          <div className={classes.progress__bg} />
        }
        {progress === 100 && dataDefected
          ? (
            <PalmReadingApproveFooter
              okText={t(`${tKey}.images.report.btn`)}
              onOkClick={handleDone}
              cancelText={t(`palm_reading.QUIZ_PALM_READING_APPROVE.back`)}
              onCancelClick={handleRetakeClick}
            />
          )
          : dataFetched
            ? (
              <>
                <Title className={classes.progress__number}>{progress}%</Title>
                <div className={classes.progress__line_inactive}>
                  <div className={classes.progress__line_active} style={{ width: `${progress}%` }} />
                </div>
                <div className={classes.progress__titles}>
                  {stages.map(({ step, title, maxStep }) => {
                    const isActiveElement = progress <= maxStep && progress >= step;
                    const titleClass = classNames(
                      classes.progress__title,
                      { [classes.progress__title_active]: isActiveElement }
                    );

                    return (
                      <Title key={title} className={titleClass}>
                        {title}...
                      </Title>
                    )
                  })}
                </div>
              </>
            )
            : (
              <>
                <Title className={classes.progress__number}>{t(`${tKey}.title`)}</Title>
                <div className={classes.progress__line_inactive}>
                  <div className={classNames(classes.progress__line_active, classes.progress__line_animated)} />
                </div>
              </>
            )
        }
      </div>
    </div>
  );
};

export default PalmReadingAnalyzing;
