import React from 'react';

import { LString, tm } from '@web-solutions/module-localization';

import { Spinner } from '../spinner';

import classes from './style.module.scss';

interface Props {
  className?: string;
  withText?: boolean;
  text?: LString;
}

const Preloader: React.FC<Props> = ({ className, withText, text, }) => (
  <Spinner className={className}>
    {withText && <span className={classes.text}>{tm(text, 'core.wait_text')}</span>}
  </Spinner>
);

export default Preloader;
