import { type FC, useState } from 'react'

import { ContactUsModal } from '@web-solutions/core/components/contact-us-modal';

import type { ThemeMode } from '@web-solutions/core/interfaces/theme';

import {
  useShowTermsMenu,
  useGetOpenHandler
} from "./hooks";

import { TermsMenu } from ".";

export interface TermsMenuMediatorProps {
  additionalEffectForShowing?: boolean,
  theme?: ThemeMode,
  customItem?: React.ReactNode,
  withContactUsForm?: boolean
  onOpenMenu?: (isOpen: boolean) => void
}

export const TermsMenuMediator: FC<TermsMenuMediatorProps> = ({ theme, customItem, additionalEffectForShowing = true, withContactUsForm = false, onOpenMenu }) => {
  const showTermsMenu = useShowTermsMenu() && additionalEffectForShowing;

  const [showContactModal, setShowContactModal] = useState(false)

  const handleOpenMenu = useGetOpenHandler({ onOpenMenu })

  const handleContactClick = withContactUsForm ?
    () => {
      setShowContactModal(true)
    }
    : undefined

  const handleCloseModal = () => {
    setShowContactModal(false)
  }


  return (
    <>
      <TermsMenu show={showTermsMenu} theme={theme} customItem={customItem} onOpenMenu={handleOpenMenu} onContactClick={handleContactClick} />
      {withContactUsForm &&
        <ContactUsModal
          active={showContactModal}
          openLocation='terms_menu'
          onCloseModal={handleCloseModal}
          onCloseModalAfterSubmit={handleCloseModal}
        />
      }
    </>
  )
}
