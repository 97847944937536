import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

import { ReactComponent as Icon } from './icons/preloader.svg';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export const Spinner: React.FC<Props> = ({ className, children, }) => (
  <div className={classNames(classes.wrapperSpinner, className)}>
    <span className={classes.spinner}>
      <Icon width="50px" height="50px" />
    </span>
    {children}
  </div>
);

