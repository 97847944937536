import { useState, useCallback, useLayoutEffect } from 'react'

import { checkIsRootFixed } from '@web-solutions/core/utils/check-is-root-fixed';

export const useGetOpenHandler = ({ onOpenMenu }: { onOpenMenu?: (isOpen: boolean) => void }) => {
  const [fixedGlobalElement, setFixedGlobalElement] = useState(document.body)

  useLayoutEffect(() => {
    if (checkIsRootFixed()) {
      const rootEl = document.getElementById('root')

      if (rootEl) {
        setFixedGlobalElement(rootEl)
      }
    }
  }, [])

  const handleOpenMenu = useCallback((isOpen: boolean) => {
    fixedGlobalElement.style.overflow = isOpen ? 'hidden' : 'auto';

    onOpenMenu && onOpenMenu(isOpen)
  }, [fixedGlobalElement, onOpenMenu])

  return handleOpenMenu
}
