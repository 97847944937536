import { createAsyncThunk, type ThunkAction } from '@reduxjs/toolkit';

import { getPalmReport, savePalm } from '../src/api/profile';

import { slice } from '.'

export const { setHandImg, setPalmReport, setPalmWithLines } = slice.actions;

export const getPalmReportData = createAsyncThunk(
  `${slice.name}/getPalmReportData`,
  async (_, { dispatch }) => {
    getPalmReport()
      .then((r: any) => {
        if (r.palm_reading) {
          try {
            const report = {
              ...r.palm_reading,
              left_hand_dots: r.palm_reading.left_hand_dots ? JSON.parse(r.palm_reading.left_hand_dots) : null,
              right_hand_dots: r.palm_reading.right_hand_dots ? JSON.parse(r.palm_reading.right_hand_dots) : null
            }
            dispatch(setPalmReport(report));
          } catch (err) {
            console.log('[PARSING ERROR]:', err)
          }
        }
      });
  }
)

export const savePalmData = (url: string, data: any): ThunkAction<Promise<void>, any, unknown, any> => async (dispatch) => {
  const formData = new FormData();
  formData.append('image_file', url.replace('data:image/jpeg;base64,', ''));
  if (data) {
    formData.append('dots', JSON.stringify(data));
  }

  return savePalm(formData)
    .then((r: any) => {
      const palmReading = r.palm_reading
      try {
        const report = {
          ...palmReading,
          left_hand_dots: palmReading.left_hand_dots ? JSON.parse(palmReading.left_hand_dots) : null,
          right_hand_dots: palmReading.right_hand_dots ? JSON.parse(palmReading.right_hand_dots) : null
        }
        dispatch(setPalmReport(report));
      } catch (err) {
        console.log('[PARSING ERROR]:', err)
      }
    });
}

