import type { FC, ComponentProps } from 'react'

import classNames from 'classnames';

import { ReactComponent as Icon } from '../spinner/icons/preloader.svg';

import { ArrowIcon } from './icons'

import classes from './style.module.scss';

interface SelectProps extends ComponentProps<'select'> {
  options: ComponentProps<'option'>[];
  iconClassName?: string;
  loading?: boolean;
  placeholderText?: string;
  classNameWrap?: string;
}

export const Select: FC<SelectProps> = ({ placeholderText, options, className, classNameWrap, iconClassName, loading, ...props }) => (
  <div className={classNames(classes.wrapper, classNameWrap)}>
    {loading
      ? (
        <span className={classes.loader}><Icon width="20px" height="20px" /></span>
      )
      : (
        <ArrowIcon className={classNames(classes.icon, iconClassName)} />
      )}
    <select className={classNames(classes.select, className)} {...props}>
      {placeholderText && <option value="" disabled>{placeholderText}</option>}
      {
        options.map(({ value, label }) => (
          <option
            key={String(value)}
            value={value}
          >
            {label || value}
          </option>
        ))
      }
    </select>
  </div>

)
