import Localization from '@web-solutions/module-localization';

import { APP_NAME, SUPPORT_EMAIL } from 'src/constants/general';

export function initLocalization() {
  Localization.init(
    'astroline-shop',
    { appName: APP_NAME, supportEmail: SUPPORT_EMAIL },
    { excludeBase: true, withPR: true, supportLangs: { 'en': 'en' } }
  );
}
