export const checkIsRootFixed = () => {
  const root = document.getElementById('root')

  if (root) {
    const positionValue = getComputedStyle(root).getPropertyValue('position')

    return positionValue === 'relative' || positionValue === 'fixed'
  }

  return false
}