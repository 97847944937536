import _map from 'lodash/map';
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { t, T } from '@web-solutions/module-localization';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import { Theme } from '@web-solutions/core/constants/theme';

import { Text, LinkButton } from '@web-solutions/core/ui-elements';

import { useGetScrollToBottomHandler } from '@web-solutions/core/hooks/use-get-scroll-to-bottom-handler';

import type { ImageSource } from '@web-solutions/core/interfaces/images';
import { QuizStepKind, QuizStepView } from '@web-solutions/core/containers/questions/types'
import type { QuizData } from '@web-solutions/core/interfaces/quiz';

import { DATA_COMPONENTS } from '../constants';

import NoteBlock from '../components/note-block';

import { IMAGES } from './images/rates';
import { ReactComponent as IconQuotes } from './images/quotes.svg';

import classes from './style.module.scss';

const tKey = 'core';

export interface SingleAnswersProps {
  analyticsCategory: string;
  icons?: {
    [key: string]: string
  };
  images?: {
    [key: string]: ImageSource;
  };
  headImage?: ImageSource;
  kind?: QuizStepKind;
  view?: QuizStepView;
  onClick: (id: string) => void;
  data: QuizData;
  theme?: Theme;
  colors?: {
    [key: string]: string
  };
  withNoteBlock?: boolean;
  columnsNumber?: 2 | 3;
  rate?: ImageSource;
  onSkipClick?: () => void;
  subTitleClassName?: string;
  titleClassName?: string;
  fullWidth?: boolean;
  duoButtons?: boolean;
  isHeadImageBeforeTitle?: boolean;
  headImageBg?: ImageSource;
  answerClassName?: string;
  imgClassName?: string;
}

const SingleAnswers: React.FC<SingleAnswersProps> = ({
  analyticsCategory,
  icons,
  images,
  headImage,
  kind = QuizStepKind.Rectangle,
  view,
  onClick,
  data,
  theme = Theme.light,
  colors,
  columnsNumber = 2,
  onSkipClick,
  withNoteBlock = false,
  titleClassName,
  subTitleClassName,
  duoButtons,
  isHeadImageBeforeTitle,
  headImageBg,
  answerClassName,
  imgClassName
}) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const scrollToBottom = useGetScrollToBottomHandler();

  const { answers, uptitle, title, subtitle, skip, noteContent } = data;

  const AnswerList = DATA_COMPONENTS[kind];
  const dataTypeClassName = `answers-list_${kind}`;

  const isTitleBg = (kind === QuizStepKind.Bipolar && !headImageBg) || kind === QuizStepKind.Numeric;
  const isHeadImageBg = kind === QuizStepKind.Bipolar && headImageBg;
  const isNoteBlockAvailable = Boolean(withNoteBlock && noteContent);

  const titleClassNames = classNames(classes.title, titleClassName, { [classes.with_subtitle]: !!subtitle });
  const listClassName = classNames(classes['answers-list'], classes[dataTypeClassName]);
  const classQuestion = classNames(classes.question, {
    [classes.dark]: theme === Theme.dark,
  })

  const memoizedAnswers = useMemo(() => {
    switch (kind) {
      case QuizStepKind.Bipolar:
        return t(`core.bipolar.answers`, { returnObjects: true }) as unknown as { [key: string]: string };
      case QuizStepKind.Numeric:
        return { 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' };
      case QuizStepKind.Rate:
        return {
          1: IMAGES.BIG_BAD,
          2: IMAGES.SMALL_BAD,
          3: IMAGES.NORMAL,
          4: IMAGES.SMALL_GOOD,
          5: IMAGES.BIG_GOOD
        };
      default:
        return answers;
    }
  }, [kind, answers]);

  useEffect(() => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.OPEN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsCategory]);

  const handleAnswerClick = (id: string) => {
    if (isNoteBlockAvailable) {
      scrollToBottom();

      setSelectedId(id);
    } else {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: id });

      setSelectedId(id);

      onClick(id);
    }
  }

  const handleButtonClick = () => {
    if (isNoteBlockAvailable) {
      Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SUCCESS, { answer: selectedId });

      onClick(selectedId || '');
    }
  }

  const handleSkipClick = () => {
    Analytics.trackEvent(analyticsCategory, EVENT_ACTION.SKIP);
    onSkipClick && onSkipClick();
  }

  return (
    <div className={classQuestion}>
      {uptitle && <Text className={classes.uptitle}>{uptitle}</Text>}
      {
        (headImage && isHeadImageBeforeTitle) &&
        <img className={classNames(classes.headImage, classes.top)} src={headImage.src} srcSet={headImage.srcSet} alt='headImage' />
      }
      <div className={classNames(classes.title_wrap, { [classes.block]: isHeadImageBg })}>
        {isTitleBg && <IconQuotes className={classes.quotes} />}
        {isHeadImageBg && <img className={classes.headImageBg} src={headImageBg.src} srcSet={headImageBg.srcSet} alt='headImageBg' />}
        {title && <h1 className={titleClassNames}><T k={title} /></h1>}
      </div>
      {subtitle && <Text className={classNames(classes.subtitle, subTitleClassName)}>{subtitle}</Text>}
      {
        (headImage && !isHeadImageBeforeTitle) &&
        <img className={classes.headImage} src={headImage.src} srcSet={headImage.srcSet} alt='headImage' />
      }
      <div className={classes['inner-wrap']}>
        <ul className={listClassName}>
          {_map(memoizedAnswers, (v, k) => (
            <AnswerList
              key={`answer_${k}`}
              classNameSquare={answerClassName}
              className={classNames(classes.item, { [classes.tall]: isHeadImageBeforeTitle })}
              onClick={handleAnswerClick}
              selected={selectedId === k}
              // @ts-ignore
              title={v.title || v}
              // @ts-ignore
              subtitle={v.subtitle || ''}
              id={k}
              // @ts-ignore
              icon={icons?.[k]}
              // @ts-ignore
              image={images?.[k]}
              isPositive={k === 'yes'}
              theme={theme}
              color={colors ? colors[k] : ''}
              columnsNumber={columnsNumber}
              // @ts-ignore
              rate={v}
              duoButtons={duoButtons}
              view={view}
              imgClassName={imgClassName}
            />
          )
          )}
          {kind === QuizStepKind.Numeric && (
            <div className={classes.number_wraper}>
              <div>{t(`${tKey}.number.left`)}</div>
              <div>{t(`${tKey}.number.right`)}</div>
            </div>
          )}
          {kind === QuizStepKind.Rate && (
            <div className={classes.rate_wraper}>
              <div className={classes.rate_text}>{t(`${tKey}.rate.left`)}</div>
              <div className={classes.rate_text}>{t(`${tKey}.rate.right`)}</div>
            </div>
          )}
        </ul>
      </div>
      {skip && <LinkButton className={classes.skip} onClick={handleSkipClick}>{skip}</LinkButton>}
      {isNoteBlockAvailable && (
        <NoteBlock
          visible={Boolean(noteContent && selectedId)}
          icon={noteContent?.icon}
          title={(selectedId ? noteContent?.answers[selectedId]?.title : noteContent?.answers.default?.title) || noteContent?.answers.default?.title}
          text={(selectedId ? noteContent?.answers[selectedId]?.text : noteContent?.answers.default?.text) || noteContent?.answers.default?.text}
          onContinue={handleButtonClick}
        />
      )}
    </div>
  );
};

export default SingleAnswers;
