import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { quizProgressSelector } from 'core/store/quiz/selectors';

export const useQuizFirstStepAnalytics = (flow: string[], event = 'QUIZ_FIRST_STEP') => {
  const progress = useSelector(quizProgressSelector);
  const track = useRef({
    first: false,
    second: false,
  });

  useEffect(() => {
    const pageName = flow[0];
    if (!pageName) return;

    if (progress?.prevStep === -1 && !track.current.first) {
      track.current.first = true;
      Analytics.trackEvent(event, EVENT_ACTION.OPEN, { pageName });
    }

    if (progress?.prevStep === 0 && !track.current.second) {
      track.current.second = true;
      Analytics.trackEvent(event, EVENT_ACTION.SUCCESS, { pageName });
    }
  }, [progress, flow, event])
}