import { type FC, StrictMode, Suspense, } from 'react';
import queryString from 'query-string';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Attribution, { LinkTarget } from '@web-solutions/module-attribution';
import Analytics from '@web-solutions/module-analytics';

import { Loader } from '@web-solutions/core/components/loader';

import { useSetKeyboardInsetHeightVar } from '@web-solutions/core/hooks/use-set-keyboard-inset-height-var';

import { parseUrlParams } from '@web-solutions/core/utils/url-sync';

import { load } from 'src/store/app/actions';
import { getMagnusToken } from 'src/store/app/utils';

import { APP_LINKS } from 'src/constants/general';

import { Routing } from 'src/routing'

import packageInfo from '../package.json';

import configureStore from './store';
import { initLocalization } from './localization';

import 'src/styles/global.scss';
import 'src/styles/index.scss';
import 'src/styles/variables.css';

const { store, persistor } = configureStore();

const p = queryString.parse(window.location.search);

const App: FC = () => {
  useSetKeyboardInsetHeightVar()

  return (
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loader />}>
            <Routing />
          </Suspense>
        </PersistGate>
      </Provider>
    </StrictMode>
  );
};

const init = async () => {
  if (p.reset !== undefined) {
    setInterval(() => {
      window.localStorage.clear();
    }, 10);
    setTimeout(() => {
      delete p.reset;
    }, 1000);
  }
  initLocalization();
  await Analytics.init(getMagnusToken(), packageInfo.version);

  Attribution.init(APP_LINKS);
  Attribution.setRemoteConfigTarget(LinkTarget.WEB);

  return store.dispatch(load());
}

export const render = () => {
  parseUrlParams();
  const container = document.getElementById('root');
  const root = createRoot(container!);
  init().then(() => {
    root.render(<App />);
  })
};

render();
