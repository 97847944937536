import queryString from 'query-string';
import { ThunkAction } from 'redux-thunk';

import { setPurchase } from '@web-solutions/core/store/billing/actions';
import { remoteConfigSelector } from '@web-solutions/core/store/remote-config/selectors';
import { REDIRECT_TO_BROWSER, doRedirectToBrowser } from '@web-solutions/core/utils/redirect-to-browser';
import { type Purchase } from '@web-solutions/core/interfaces/billing';

import { selectFlow } from 'src/store/routing/selectors';
import { processEmail } from 'src/store/profile/actions';
import { ROUTES } from 'src/constants/routes';


import { routingSlice } from '.';

export const { setCompleted, setStep, setStartRoute } = routingSlice.actions;

export const init = (): ThunkAction<Promise<void>, any, unknown, any> => async (dispatch, getState) => {
  const { redirectToBrowser } = remoteConfigSelector(getState());
  const flow = selectFlow(getState());

  const {
    billing: { purchase },
  } = getState() as any;

  if (redirectToBrowser === REDIRECT_TO_BROWSER.ENABLED) {
    doRedirectToBrowser({ mode: redirectToBrowser });
  }

  const params = queryString.parse(window.location.search);

  if (params.purchased !== undefined) {
    dispatch(setPurchase({} as Purchase));
  }

  if (purchase) {
    sessionStorage.setItem('completed', 'true');
    dispatch(setCompleted(true));
  }

  dispatch(setCompleted(params.purchased !== undefined || params.completed !== undefined || sessionStorage.getItem('completed') === 'true'));

  if (params.email) {
    dispatch(processEmail(params.email as string));
  }

  const {
    routing: { isCompleted },
  } = getState();

  dispatch(setStep(0));

  const startRoute = !isCompleted ? `/${flow[0]}` : await dispatch(getRouteAfterFlow());
  dispatch(setStartRoute(startRoute));

  return Promise.resolve();
};

export const getRouteAfterFlow = (): ThunkAction<string, any, unknown, any> => (dispatch, getState) => {
  const { purchased } = queryString.parse(window.location.search);

  if (purchased !== undefined) {
    return ROUTES.SUCCESS;
  }

  return ROUTES.MAIN;
};
