import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PersonalizedData = {
  birth_date: {
    day: string,
    month: string,
    year: string,
  },
  birth_time: {
    hour: string,
    minutes: string,
    dont_now: boolean
  },
  location: {
    label: string,
    lat: string,
    lon: string
  },
}

export type ShippingInformation = {
  email: string,
  name: string,
  last_name: string,
  country: {
    value: string,
    label: string,
    state: boolean,
  },
  state: {
    value: string,
    label: string,
  },
  city: string,
  street: string,
  apartment: string,
  zip_code: string,
  phone: string,
  checks: {
    personal: boolean,
    terms: boolean
  }
}

export type ShippingInformationShort = Pick<ShippingInformation, 'email' | 'name' | 'last_name' | 'checks'>;

export type ShippingMethod = {
  id: string,
  deliverier: string,
  shipping_level: string,
  delivery_interval: string,
  price: string,
  currency: string,
}

export interface ProfileState {
  birthDate: string | null; // YYYY-MM-DD
  birthTime: string | null; // HH:mm
  birthPlace: Record<'label' | 'lat' | 'lon', string> | null;

  approximateLocation: {
    lat: string,
    lon: string,
    country_code: string,
    label: string,
  } | null;
  shippingInformation: ShippingInformation;
  shippingMethod: ShippingMethod
  personalizedData: PersonalizedData | null;
  email: string;
}

const initialState: ProfileState = {
  birthDate: null, // YYYY-MM-DD
  birthTime: null, // HH:mm
  birthPlace: null, // { label: string, lat: string, lon: string }

  approximateLocation: null,
  shippingInformation: {
    email: '',
    name: '',
    last_name: '',
    country: {
      value: '',
      label: '',
      state: false,
    },
    state: {
      value: '',
      label: '',
    },
    city: '',
    street: '',
    apartment: '',
    zip_code: '',
    phone: '',
    checks: {
      personal: false,
      terms: false
    }
  },
  shippingMethod: {
    id: '',
    deliverier: '',
    shipping_level: '',
    delivery_interval: '',
    price: '',
    currency: ''
  },
  personalizedData: null,
  email: '',
};


const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload || initialState.email;
    },
    setApproximateLocation: (state, action: PayloadAction<{
      lat: string,
      lon: string,
      country_code: string,
      label: string,
    }>) => {
      state.approximateLocation = action.payload;
    },
    setPersonalizedData: (state, action: PayloadAction<Partial<PersonalizedData>>) => {
      //@ts-ignore next-l
      state.personalizedData = { ...(state.personalizedData ?? {}), ...action.payload };
    },
    setShippingInformation: (state, action: PayloadAction<ShippingInformation>) => {
      state.shippingInformation = action.payload;
    },
    setShippingMethod: (state, action: PayloadAction<ShippingMethod>) => {
      state.shippingMethod = action.payload;
    },
    setBirthTime: (state, action: PayloadAction<any>) => {
      state.birthTime = action.payload;
    },
    setBirthDate: (state, action: PayloadAction<any>) => {
      state.birthDate = action.payload;
    },
    setBirthPlace: (state, action: PayloadAction<any>) => {
      state.birthPlace = action.payload;
    },
    clearPersonalizedData: (state) => {
      state.personalizedData = null;
    },
    clearShippingInformation: (state) => {
      state.shippingInformation = {
        email: '',
        name: '',
        last_name: '',
        country: {
          value: '',
          label: '',
          state: false,
        },
        state: {
          value: '',
          label: '',
        },
        city: '',
        street: '',
        apartment: '',
        zip_code: '',
        phone: '',
        checks: {
          personal: false,
          terms: false,
        },
      };
    },
    clearShippingMethod: (state) => {
      state.shippingMethod = {
        id: '',
        deliverier: '',
        shipping_level: '',
        delivery_interval: '',
        price: '',
        currency: '',
      };
    },
  }
})

export const blacklist = [];

export { slice as profileSlice };
