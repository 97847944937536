import { CountryData } from "@web-solutions/core/types/location-prices-types";

// MOCK FOR TEST PERIOD
export const COUNTRIES_DATA: Record<'USD' | 'EUR', Record<string, CountryData>> = {
  USD: {
    "AE": { currency_code: "AED", currency_symbol: "د.إ", exchange_rate: 3.672995 },
    "AF": { currency_code: "AFN", currency_symbol: "؋", exchange_rate: 71.797341 },
    "AL": { currency_code: "ALL", currency_symbol: "L", exchange_rate: 91.957642 },
    "AM": { currency_code: "AMD", currency_symbol: "֏", exchange_rate: 390.526243 },
    "AN": { currency_code: "ANG", currency_symbol: "ƒ", exchange_rate: 1.790208 },
    "AO": { currency_code: "AOA", currency_symbol: "Kz", exchange_rate: 916.999967 },
    "AR": { currency_code: "ARS", currency_symbol: "$", exchange_rate: 1072.805403 },
    "AU": { currency_code: "AUD", currency_symbol: "$", exchange_rate: 1.584972 },
    "AW": { currency_code: "AWG", currency_symbol: "ƒ", exchange_rate: 1.8025 },
    "AZ": { currency_code: "AZN", currency_symbol: "₼", exchange_rate: 1.714885 },
    "BA": { currency_code: "BAM", currency_symbol: "KM", exchange_rate: 1.81101 },
    "BB": { currency_code: "BBD", currency_symbol: "$", exchange_rate: 2.019095 },
    "BD": { currency_code: "BDT", currency_symbol: "৳", exchange_rate: 121.514885 },
    "BG": { currency_code: "BGN", currency_symbol: "лв", exchange_rate: 1.81191 },
    "BH": { currency_code: "BHD", currency_symbol: ".د.ب", exchange_rate: 0.376953 },
    "BI": { currency_code: "BIF", currency_symbol: "FBu", exchange_rate: 2972.000611 },
    "BM": { currency_code: "BMD", currency_symbol: "$", exchange_rate: 1.000000 },
    "BN": { currency_code: "BND", currency_symbol: "$", exchange_rate: 1.343156 },
    "BO": { currency_code: "BOB", currency_symbol: "$b", exchange_rate: 6.909579 },
    "BR": { currency_code: "BRL", currency_symbol: "R$", exchange_rate: 5.682902 },
    "BS": { currency_code: "BSD", currency_symbol: "$", exchange_rate: 0.999958 },
    "BT": { currency_code: "BTN", currency_symbol: "Nu.", exchange_rate: 85.678698 },
    "BW": { currency_code: "BWP", currency_symbol: "P", exchange_rate: 13.72697 },
    "BY": { currency_code: "BYN", currency_symbol: "Br", exchange_rate: 3.272567 },
    "BZ": { currency_code: "BZD", currency_symbol: "BZ$", exchange_rate: 2.00864 },
    "CA": { currency_code: "CAD", currency_symbol: "$", exchange_rate: 1.430239 },
    "CD": { currency_code: "CDF", currency_symbol: "FC", exchange_rate: 2873.000235 },
    "CH": { currency_code: "CHF", currency_symbol: "CHF", exchange_rate: 0.884425 },
    "CL": { currency_code: "CLP", currency_symbol: "$", exchange_rate: 948.720116 },
    "CN": { currency_code: "CNY", currency_symbol: "¥", exchange_rate: 7.2702 },
    "CO": { currency_code: "COP", currency_symbol: "$", exchange_rate: 4165.15 },
    "CR": { currency_code: "CRC", currency_symbol: "₡", exchange_rate: 500.171323 },
    "CU": { currency_code: "CUC", currency_symbol: "$", exchange_rate: 1.000000 },
    "CV": { currency_code: "CVE", currency_symbol: "$", exchange_rate: 102.113768 },
    "CZ": { currency_code: "CZK", currency_symbol: "Kč", exchange_rate: 23.090204 },
    "DJ": { currency_code: "DJF", currency_symbol: "Fdj", exchange_rate: 178.071853 },
    "DK": { currency_code: "DKK", currency_symbol: "kr", exchange_rate: 6.90675 },
    "DO": { currency_code: "DOP", currency_symbol: "RD$", exchange_rate: 63.278479 },
    "DZ": { currency_code: "DZD", currency_symbol: "دج", exchange_rate: 133.811994 },
    "EG": { currency_code: "EGP", currency_symbol: "£", exchange_rate: 50.572022 },
    "ER": { currency_code: "ERN", currency_symbol: "Nfk", exchange_rate: 15.000000 },
    "ET": { currency_code: "ETB", currency_symbol: "Br", exchange_rate: 132.379819 },
    "EU": { currency_code: "EUR", currency_symbol: "€", exchange_rate: 0.925715 },
    "FJ": { currency_code: "FJD", currency_symbol: "$", exchange_rate: 2.33055 },
    "FK": { currency_code: "FKP", currency_symbol: "£", exchange_rate: 0.775725 },
    "GB": { currency_code: "GBP", currency_symbol: "£", exchange_rate: 0.773535 },
    "GE": { currency_code: "GEL", currency_symbol: "₾", exchange_rate: 2.760355 },
    "GG": { currency_code: "GGP", currency_symbol: "£", exchange_rate: 0.775725 },
    "GH": { currency_code: "GHS", currency_symbol: "¢", exchange_rate: 15.49794 },
    "GI": { currency_code: "GIP", currency_symbol: "£", exchange_rate: 0.775725 },
    "GM": { currency_code: "GMD", currency_symbol: "D", exchange_rate: 72.000037 },
    "GN": { currency_code: "GNF", currency_symbol: "FG", exchange_rate: 8653.093731 },
    "GT": { currency_code: "GTQ", currency_symbol: "Q", exchange_rate: 7.716991 },
    "GY": { currency_code: "GYD", currency_symbol: "$", exchange_rate: 209.80601 },
    "HK": { currency_code: "HKD", currency_symbol: "$", exchange_rate: 7.781375 },
    "HN": { currency_code: "HNL", currency_symbol: "L", exchange_rate: 25.585509 },
    "HR": { currency_code: "HRK", currency_symbol: "kn", exchange_rate: 6.976101 },
    "HT": { currency_code: "HTG", currency_symbol: "G", exchange_rate: 131.12067 },
    "HU": { currency_code: "HUF", currency_symbol: "Ft", exchange_rate: 371.246497 },
    "ID": { currency_code: "IDR", currency_symbol: "Rp", exchange_rate: 16705.1 },
    "IL": { currency_code: "ILS", currency_symbol: "₪", exchange_rate: 3.690345 },
    "IM": { currency_code: "IMP", currency_symbol: "£", exchange_rate: 0.775725 },
    "IN": { currency_code: "INR", currency_symbol: "₹", exchange_rate: 85.54325 },
    "IQ": { currency_code: "IQD", currency_symbol: "ع.د", exchange_rate: 1309.875457 },
    "IR": { currency_code: "IRR", currency_symbol: "﷼", exchange_rate: 42100.000108 },
    "IS": { currency_code: "ISK", currency_symbol: "kr", exchange_rate: 133.029974 },
    "JE": { currency_code: "JEP", currency_symbol: "£", exchange_rate: 0.775725 },
    "JM": { currency_code: "JMD", currency_symbol: "J$", exchange_rate: 157.358103 },
    "JO": { currency_code: "JOD", currency_symbol: "JD", exchange_rate: 0.709006 },
    "JP": { currency_code: "JPY", currency_symbol: "¥", exchange_rate: 149.611498 },
    "KE": { currency_code: "KES", currency_symbol: "KSh", exchange_rate: 129.25043 },
    "KG": { currency_code: "KGS", currency_symbol: "лв", exchange_rate: 86.663198 },
    "KH": { currency_code: "KHR", currency_symbol: "៛", exchange_rate: 3999.648098 },
    "KM": { currency_code: "KMF", currency_symbol: "CF", exchange_rate: 456.499624 },
    "KP": { currency_code: "KPW", currency_symbol: "₩", exchange_rate: 900.006188 },
    "KR": { currency_code: "KRW", currency_symbol: "₩", exchange_rate: 1462.669628 },
    "KW": { currency_code: "KWD", currency_symbol: "د.ك", exchange_rate: 0.30835 },
    "KY": { currency_code: "KYD", currency_symbol: "$", exchange_rate: 0.833279 },
    "KZ": { currency_code: "KZT", currency_symbol: "₸", exchange_rate: 503.365897 },
    "LA": { currency_code: "LAK", currency_symbol: "₭", exchange_rate: 21659.991202 },
    "LB": { currency_code: "LBP", currency_symbol: "£", exchange_rate: 89599.981478 },
    "LK": { currency_code: "LKR", currency_symbol: "₨", exchange_rate: 296.041483 },
    "LR": { currency_code: "LRD", currency_symbol: "$", exchange_rate: 200.00463 },
    "LS": { currency_code: "LSL", currency_symbol: "L", exchange_rate: 18.378348 },
    "LY": { currency_code: "LYD", currency_symbol: "LD", exchange_rate: 4.835879 },
    "MA": { currency_code: "MAD", currency_symbol: "د.م.", exchange_rate: 9.63915 },
    "MD": { currency_code: "MDL", currency_symbol: "lei", exchange_rate: 17.929757 },
    "MG": { currency_code: "MGA", currency_symbol: "Ar", exchange_rate: 4674.744987 },
    "MK": { currency_code: "MKD", currency_symbol: "ден", exchange_rate: 56.991891 },
    "MM": { currency_code: "MMK", currency_symbol: "K", exchange_rate: 2099.395551 },
    "MN": { currency_code: "MNT", currency_symbol: "₮", exchange_rate: 3480.738027 },
    "MO": { currency_code: "MOP", currency_symbol: "MOP$", exchange_rate: 8.014167 },
    "MR": { currency_code: "MRU", currency_symbol: "UM", exchange_rate: 39.77941 },
    "MU": { currency_code: "MUR", currency_symbol: "₨", exchange_rate: 45.809518 },
    "MV": { currency_code: "MVR", currency_symbol: "Rf", exchange_rate: 15.398954 },
    "MW": { currency_code: "MWK", currency_symbol: "MK", exchange_rate: 1733.952558 },
    "MX": { currency_code: "MXN", currency_symbol: "$", exchange_rate: 20.340498 },
    "MY": { currency_code: "MYR", currency_symbol: "RM", exchange_rate: 4.452987 },
    "MZ": { currency_code: "MZN", currency_symbol: "MT", exchange_rate: 63.909847 },
    "NA": { currency_code: "NAD", currency_symbol: "$", exchange_rate: 18.376221 },
    "NG": { currency_code: "NGN", currency_symbol: "₦", exchange_rate: 1534.879927 },
    "NI": { currency_code: "NIO", currency_symbol: "C$", exchange_rate: 36.801578 },
    "NO": { currency_code: "NOK", currency_symbol: "kr", exchange_rate: 10.442802 },
    "NP": { currency_code: "NPR", currency_symbol: "₨", exchange_rate: 137.085467 },
    "NZ": { currency_code: "NZD", currency_symbol: "$", exchange_rate: 1.74139 },
    "OM": { currency_code: "OMR", currency_symbol: "﷼", exchange_rate: 0.384981 },
    "PA": { currency_code: "PAB", currency_symbol: "B/.", exchange_rate: 0.999861 },
    "PE": { currency_code: "PEN", currency_symbol: "S/.", exchange_rate: 3.674522 },
    "PG": { currency_code: "PGK", currency_symbol: "K", exchange_rate: 4.12374 },
    "PH": { currency_code: "PHP", currency_symbol: "₱", exchange_rate: 57.177006 },
    "PK": { currency_code: "PKR", currency_symbol: "₨", exchange_rate: 280.169839 },
    "PL": { currency_code: "PLN", currency_symbol: "zł", exchange_rate: 3.86795 },
    "PY": { currency_code: "PYG", currency_symbol: "Gs", exchange_rate: 7984.516225 },
    "QA": { currency_code: "QAR", currency_symbol: "﷼", exchange_rate: 3.646887 },
    "RO": { currency_code: "RON", currency_symbol: "lei", exchange_rate: 4.607802 },
    "RS": { currency_code: "RSD", currency_symbol: "Дин.", exchange_rate: 108.506766 },
    "RU": { currency_code: "RUB", currency_symbol: "₽", exchange_rate: 84.046845 },
    "RW": { currency_code: "RWF", currency_symbol: "R₣", exchange_rate: 1425.94539 },
    "SA": { currency_code: "SAR", currency_symbol: "﷼", exchange_rate: 3.751412 },
    "SB": { currency_code: "SBD", currency_symbol: "$", exchange_rate: 8.411286 },
    "SC": { currency_code: "SCR", currency_symbol: "₨", exchange_rate: 14.715003 },
    "SD": { currency_code: "SDG", currency_symbol: "ج.س.", exchange_rate: 599.999963 },
    "SE": { currency_code: "SEK", currency_symbol: "kr", exchange_rate: 9.986035 },
    "SG": { currency_code: "SGD", currency_symbol: "$", exchange_rate: 1.343065 },
    "SH": { currency_code: "SHP", currency_symbol: "£", exchange_rate: 0.785843 },
    "SL": { currency_code: "SLE", currency_symbol: "Le", exchange_rate: 22.775029 },
    "SO": { currency_code: "SOS", currency_symbol: "S", exchange_rate: 571.478844 },
    "SR": { currency_code: "SRD", currency_symbol: "$", exchange_rate: 36.594501 },
    "SS": { currency_code: "SSP", currency_symbol: "£", exchange_rate: 130.26126 },
    "ST": { currency_code: "STN", currency_symbol: "Db", exchange_rate: 22.500000 },
    "SV": { currency_code: "SVC", currency_symbol: "$", exchange_rate: 8.748553 },
    "SY": { currency_code: "SYP", currency_symbol: "£", exchange_rate: 13001.855134 },
    "SZ": { currency_code: "SZL", currency_symbol: "L", exchange_rate: 18.364673 },
    "TH": { currency_code: "THB", currency_symbol: "฿", exchange_rate: 34.173502 },
    "TJ": { currency_code: "TJS", currency_symbol: "SM", exchange_rate: 10.893745 },
    "TM": { currency_code: "TMT", currency_symbol: "T", exchange_rate: 3.500000 },
    "TN": { currency_code: "TND", currency_symbol: "د.ت", exchange_rate: 3.108004 },
    "TO": { currency_code: "TOP", currency_symbol: "T$", exchange_rate: 2.342103 },
    "TR": { currency_code: "TRY", currency_symbol: "₺", exchange_rate: 37.907294 },
    "TT": { currency_code: "TTD", currency_symbol: "TT$", exchange_rate: 6.784573 },
    "TW": { currency_code: "TWD", currency_symbol: "NT$", exchange_rate: 33.144497 },
    "TZ": { currency_code: "TZS", currency_symbol: "TSh", exchange_rate: 2642.457949 },
    "UA": { currency_code: "UAH", currency_symbol: "₴", exchange_rate: 41.385477 },
    "UG": { currency_code: "UGX", currency_symbol: "USh", exchange_rate: 3653.872864 },
    "US": { currency_code: "USD", currency_symbol: "$", exchange_rate: 1.000000 },
    "UY": { currency_code: "UYU", currency_symbol: "$U", exchange_rate: 42.16424 },
    "UZ": { currency_code: "UZS", currency_symbol: "лв", exchange_rate: 12935.784064 },
    "VE": { currency_code: "VES", currency_symbol: "Bs.S", exchange_rate: 69.68881 },
    "VN": { currency_code: "VND", currency_symbol: "₫", exchange_rate: 25645.000000 },
    "VU": { currency_code: "VUV", currency_symbol: "VT", exchange_rate: 123.383223 },
    "WS": { currency_code: "WST", currency_symbol: "WS$", exchange_rate: 2.837441 },
    "YE": { currency_code: "YER", currency_symbol: "﷼", exchange_rate: 245.999771 },
    "ZA": { currency_code: "ZAR", currency_symbol: "R", exchange_rate: 18.568105 },
    "ZM": { currency_code: "ZMW", currency_symbol: "ZK", exchange_rate: 28.07453 },
    "ZW": { currency_code: "ZWL", currency_symbol: "Z$", exchange_rate: 321.999592 }
  },
  EUR: {
    "AE": { "currency_code": "AED", "currency_symbol": "د.إ", "exchange_rate": 3.967738 },
    "AF": { "currency_code": "AFN", "currency_symbol": "؋", "exchange_rate": 77.558796 },
    "AL": { "currency_code": "ALL", "currency_symbol": "L", "exchange_rate": 99.336882 },
    "AM": { "currency_code": "AMD", "currency_symbol": "֏", "exchange_rate": 421.864443 },
    "CW": { "currency_code": "ANG", "currency_symbol": "ƒ", "exchange_rate": 1.933865 },
    "AO": { "currency_code": "AOA", "currency_symbol": "Kz", "exchange_rate": 990.585454 },
    "AR": { "currency_code": "ARS", "currency_symbol": "$", "exchange_rate": 1158.92062 },
    "AU": { "currency_code": "AUD", "currency_symbol": "$", "exchange_rate": 1.711978 },
    "AW": { "currency_code": "AWG", "currency_symbol": "ƒ", "exchange_rate": 1.947144 },
    "AZ": { "currency_code": "AZN", "currency_symbol": "₼", "exchange_rate": 1.836093 },
    "BA": { "currency_code": "BAM", "currency_symbol": "KM", "exchange_rate": 1.956336 },
    "BB": { "currency_code": "BBD", "currency_symbol": "$", "exchange_rate": 2.18112 },
    "BD": { "currency_code": "BDT", "currency_symbol": "৳", "exchange_rate": 131.265978 },
    "BG": { "currency_code": "BGN", "currency_symbol": "лв", "exchange_rate": 1.957309 },
    "BH": { "currency_code": "BHD", "currency_symbol": ".د.ب", "exchange_rate": 0.407202 },
    "BI": { "currency_code": "BIF", "currency_symbol": "FBu", "exchange_rate": 3210.49201 },
    "BM": { "currency_code": "BMD", "currency_symbol": "$", "exchange_rate": 1.080246 },
    "BN": { "currency_code": "BND", "currency_symbol": "$", "exchange_rate": 1.450939 },
    "BO": { "currency_code": "BOB", "currency_symbol": "$b", "exchange_rate": 7.464046 },
    "BR": { "currency_code": "BRL", "currency_symbol": "R$", "exchange_rate": 6.138286 },
    "BS": { "currency_code": "BSD", "currency_symbol": "$", "exchange_rate": 1.080201 },
    "BT": { "currency_code": "BTN", "currency_symbol": "Nu.", "exchange_rate": 92.554078 },
    "BW": { "currency_code": "BWP", "currency_symbol": "P", "exchange_rate": 14.828506 },
    "BY": { "currency_code": "BYN", "currency_symbol": "Br", "exchange_rate": 3.535178 },
    "BZ": { "currency_code": "BZD", "currency_symbol": "BZ$", "exchange_rate": 2.169826 },
    "CA": { "currency_code": "CAD", "currency_symbol": "$", "exchange_rate": 1.545378 },
    "CD": { "currency_code": "CDF", "currency_symbol": "FC", "exchange_rate": 3103.54758 },
    "CH": { "currency_code": "CHF", "currency_symbol": "CHF", "exchange_rate": 0.955374 },
    "CL": { "currency_code": "CLP", "currency_symbol": "$", "exchange_rate": 1024.851188 },
    "CN": { "currency_code": "CNY", "currency_symbol": "¥", "exchange_rate": 7.853603 },
    "CO": { "currency_code": "COP", "currency_symbol": "$", "exchange_rate": 4499.38696 },
    "CR": { "currency_code": "CRC", "currency_symbol": "₡", "exchange_rate": 540.308111 },
    "CU": { "currency_code": "CUC", "currency_symbol": "$", "exchange_rate": 1.080246 },
    "CV": { "currency_code": "CVE", "currency_symbol": "$", "exchange_rate": 110.307998 },
    "CZ": { "currency_code": "CZK", "currency_symbol": "Kč", "exchange_rate": 24.937444 },
    "DJ": { "currency_code": "DJF", "currency_symbol": "Fdj", "exchange_rate": 192.361421 },
    "DK": { "currency_code": "DKK", "currency_symbol": "kr", "exchange_rate": 7.46061 },
    "DO": { "currency_code": "DOP", "currency_symbol": "RD$", "exchange_rate": 68.356329 },
    "DZ": { "currency_code": "DZD", "currency_symbol": "دج", "exchange_rate": 144.549865 },
    "EG": { "currency_code": "EGP", "currency_symbol": "£", "exchange_rate": 54.626316 },
    "ER": { "currency_code": "ERN", "currency_symbol": "Nfk", "exchange_rate": 16.203691 },
    "ET": { "currency_code": "ETB", "currency_symbol": "Br", "exchange_rate": 143.002781 },
    "FJ": { "currency_code": "FJD", "currency_symbol": "$", "exchange_rate": 2.517568 },
    "FK": { "currency_code": "FKP", "currency_symbol": "£", "exchange_rate": 0.837974 },
    "GB": { "currency_code": "GBP", "currency_symbol": "£", "exchange_rate": 0.835576 },
    "GE": { "currency_code": "GEL", "currency_symbol": "₾", "exchange_rate": 2.981517 },
    "GH": { "currency_code": "GHS", "currency_symbol": "GH₵", "exchange_rate": 16.740911 },
    "GI": { "currency_code": "GIP", "currency_symbol": "£", "exchange_rate": 0.837974 },
    "GM": { "currency_code": "GMD", "currency_symbol": "D", "exchange_rate": 77.899163 },
    "GN": { "currency_code": "GNF", "currency_symbol": "FG", "exchange_rate": 9345.426589 },
    "GT": { "currency_code": "GTQ", "currency_symbol": "Q", "exchange_rate": 8.334417 },
    "GY": { "currency_code": "GYD", "currency_symbol": "$", "exchange_rate": 226.778015 },
    "HK": { "currency_code": "HKD", "currency_symbol": "$", "exchange_rate": 8.406065 },
    "HN": { "currency_code": "HNL", "currency_symbol": "L", "exchange_rate": 27.62136 },
    "HR": { "currency_code": "HRK", "currency_symbol": "kn", "exchange_rate": 7.53299 },
    "HT": { "currency_code": "HTG", "currency_symbol": "G", "exchange_rate": 141.070727 },
    "HU": { "currency_code": "HUF", "currency_symbol": "Ft", "exchange_rate": 403.587998 },
    "ID": { "currency_code": "IDR", "currency_symbol": "Rp", "exchange_rate": 18095.204039 },
    "IL": { "currency_code": "ILS", "currency_symbol": "₪", "exchange_rate": 3.988507 },
    "IN": { "currency_code": "INR", "currency_symbol": "₹", "exchange_rate": 92.477757 },
    "IQ": { "currency_code": "IQD", "currency_symbol": "ع.د", "exchange_rate": 1413.565464 },
    "IR": { "currency_code": "IRR", "currency_symbol": "﷼", "exchange_rate": 45456.626824 },
    "IS": { "currency_code": "ISK", "currency_symbol": "kr", "exchange_rate": 143.906168 },
    "JM": { "currency_code": "JMD", "currency_symbol": "J$", "exchange_rate": 169.072667 },
    "JO": { "currency_code": "JOD", "currency_symbol": "JD", "exchange_rate": 0.765877 },
    "JP": { "currency_code": "JPY", "currency_symbol": "¥", "exchange_rate": 161.673404 },
    "KE": { "currency_code": "KES", "currency_symbol": "KSh", "exchange_rate": 139.607505 },
    "KG": { "currency_code": "KGS", "currency_symbol": "лв", "exchange_rate": 93.362613 },
    "KH": { "currency_code": "KHR", "currency_symbol": "៛", "exchange_rate": 4315.837712 },
    "KM": { "currency_code": "KMF", "currency_symbol": "CF", "exchange_rate": 492.927806 },
    "KP": { "currency_code": "KPW", "currency_symbol": "₩", "exchange_rate": 972.228156 },
    "KR": { "currency_code": "KRW", "currency_symbol": "₩", "exchange_rate": 1591.192942 },
    "KW": { "currency_code": "KWD", "currency_symbol": "د.ك", "exchange_rate": 0.333101 },
    "KY": { "currency_code": "KYD", "currency_symbol": "$", "exchange_rate": 0.898476 },
    "KZ": { "currency_code": "KZT", "currency_symbol": "₸", "exchange_rate": 543.810438 },
    "LA": { "currency_code": "LAK", "currency_symbol": "₭", "exchange_rate": 23378.305031 },
    "LB": { "currency_code": "LBP", "currency_symbol": "ل.ل", "exchange_rate": 96565.315584 },
    "LK": { "currency_code": "LKR", "currency_symbol": "₨", "exchange_rate": 319.064032 },
    "LR": { "currency_code": "LRD", "currency_symbol": "$", "exchange_rate": 216.007131 },
    "LS": { "currency_code": "LSL", "currency_symbol": "L", "exchange_rate": 19.808387 },
    "LT": { "currency_code": "LTL", "currency_symbol": "Lt", "exchange_rate": 3.189686 },
    "LV": { "currency_code": "LVL", "currency_symbol": "Ls", "exchange_rate": 0.65343 },
    "LY": { "currency_code": "LYD", "currency_symbol": "LD", "exchange_rate": 5.203836 },
    "MA": { "currency_code": "MAD", "currency_symbol": "د.م.", "exchange_rate": 10.40233 },
    "MD": { "currency_code": "MDL", "currency_symbol": "L", "exchange_rate": 19.420436 },
    "MG": { "currency_code": "MGA", "currency_symbol": "Ar", "exchange_rate": 5051.608124 },
    "MK": { "currency_code": "MKD", "currency_symbol": "ден", "exchange_rate": 61.564919 },
    "MM": { "currency_code": "MMK", "currency_symbol": "K", "exchange_rate": 2267.863815 },
    "MN": { "currency_code": "MNT", "currency_symbol": "₮", "exchange_rate": 3760.05361 },
    "MO": { "currency_code": "MOP", "currency_symbol": "MOP$", "exchange_rate": 8.657503 },
    "MR": { "currency_code": "MRU", "currency_symbol": "UM", "exchange_rate": 42.941238 },
    "MU": { "currency_code": "MUR", "currency_symbol": "₨", "exchange_rate": 49.282607 },
    "MV": { "currency_code": "MVR", "currency_symbol": "Rf", "exchange_rate": 16.679461 },
    "MW": { "currency_code": "MWK", "currency_symbol": "MK", "exchange_rate": 1872.09497 },
    "MX": { "currency_code": "MXN", "currency_symbol": "$", "exchange_rate": 21.975013 },
    "MY": { "currency_code": "MYR", "currency_symbol": "RM", "exchange_rate": 4.793046 },
    "MZ": { "currency_code": "MZN", "currency_symbol": "MT", "exchange_rate": 69.014244 },
    "NA": { "currency_code": "NAD", "currency_symbol": "$", "exchange_rate": 19.808387 },
    "NG": { "currency_code": "NGN", "currency_symbol": "₦", "exchange_rate": 1659.513442 },
    "NI": { "currency_code": "NIO", "currency_symbol": "C$", "exchange_rate": 39.722623 },
    "NO": { "currency_code": "NOK", "currency_symbol": "kr", "exchange_rate": 11.281674 },
    "NP": { "currency_code": "NPR", "currency_symbol": "₨", "exchange_rate": 148.033769 },
    "NZ": { "currency_code": "NZD", "currency_symbol": "$", "exchange_rate": 1.881192 },
    "OM": { "currency_code": "OMR", "currency_symbol": "﷼", "exchange_rate": 0.41588 },
    "PA": { "currency_code": "PAB", "currency_symbol": "B/.", "exchange_rate": 1.080246 },
    "PE": { "currency_code": "PEN", "currency_symbol": "S/.", "exchange_rate": 3.971883 },
    "PG": { "currency_code": "PGK", "currency_symbol": "K", "exchange_rate": 4.428235 },
    "PH": { "currency_code": "PHP", "currency_symbol": "₱", "exchange_rate": 61.908391 },
    "PK": { "currency_code": "PKR", "currency_symbol": "₨", "exchange_rate": 302.595563 },
    "PL": { "currency_code": "PLN", "currency_symbol": "zł", "exchange_rate": 4.195617 },
    "PY": { "currency_code": "PYG", "currency_symbol": "Gs", "exchange_rate": 8593.147307 },
    "QA": { "currency_code": "QAR", "currency_symbol": "ر.ق", "exchange_rate": 3.9323 },
    "RO": { "currency_code": "RON", "currency_symbol": "lei", "exchange_rate": 4.987528 },
    "RS": { "currency_code": "RSD", "currency_symbol": "дин", "exchange_rate": 117.413656 },
    "RU": { "currency_code": "RUB", "currency_symbol": "₽", "exchange_rate": 91.670612 },
    "RW": { "currency_code": "RWF", "currency_symbol": "FRw", "exchange_rate": 1535.026922 },
    "SA": { "currency_code": "SAR", "currency_symbol": "ر.س", "exchange_rate": 4.05135 },
    "SB": { "currency_code": "SBD", "currency_symbol": "$", "exchange_rate": 9.181879 },
    "SC": { "currency_code": "SCR", "currency_symbol": "₨", "exchange_rate": 15.556797 },
    "SD": { "currency_code": "SDG", "currency_symbol": "ج.س.", "exchange_rate": 648.711682 },
    "SE": { "currency_code": "SEK", "currency_symbol": "kr", "exchange_rate": 10.788218 },
    "SG": { "currency_code": "SGD", "currency_symbol": "$", "exchange_rate": 1.451438 },
    "SH": { "currency_code": "SHP", "currency_symbol": "£", "exchange_rate": 0.848904 },
    "SL": { "currency_code": "SLL", "currency_symbol": "Le", "exchange_rate": 22652.221345 },
    "SO": { "currency_code": "SOS", "currency_symbol": "S", "exchange_rate": 616.346486 },
    "SR": { "currency_code": "SRD", "currency_symbol": "$", "exchange_rate": 40.083203 },
    "SS": { "currency_code": "SSP", "currency_symbol": "£", "exchange_rate": 1407.53 },
    "ST": { "currency_code": "STD", "currency_symbol": "Db", "exchange_rate": 22358.912849 },
    "SV": { "currency_code": "SVC", "currency_symbol": "$", "exchange_rate": 9.451868 },
    "SY": { "currency_code": "SYP", "currency_symbol": "£", "exchange_rate": 14045.203042 },
    "SZ": { "currency_code": "SZL", "currency_symbol": "L", "exchange_rate": 19.808387 },
    "TH": { "currency_code": "THB", "currency_symbol": "฿", "exchange_rate": 36.890815 },
    "TJ": { "currency_code": "TJS", "currency_symbol": "ЅМ", "exchange_rate": 11.758116 },
    "TM": { "currency_code": "TMT", "currency_symbol": "T", "exchange_rate": 3.77876 },
    "TN": { "currency_code": "TND", "currency_symbol": "د.ت", "exchange_rate": 3.356668 },
    "TO": { "currency_code": "TOP", "currency_symbol": "T$", "exchange_rate": 2.6017 },
    "TR": { "currency_code": "TRY", "currency_symbol": "₺", "exchange_rate": 40.954473 },
    "TT": { "currency_code": "TTD", "currency_symbol": "TT$", "exchange_rate": 7.32524 },
    "TW": { "currency_code": "TWD", "currency_symbol": "NT$", "exchange_rate": 35.902887 },
    "TZ": { "currency_code": "TZS", "currency_symbol": "TSh", "exchange_rate": 2860.960476 },
    "UA": { "currency_code": "UAH", "currency_symbol": "₴", "exchange_rate": 44.612952 },
    "UG": { "currency_code": "UGX", "currency_symbol": "USh", "exchange_rate": 3947.6911 },
    "US": { "currency_code": "USD", "currency_symbol": "$", "exchange_rate": 1.080246 },
    "UY": { "currency_code": "UYU", "currency_symbol": "$U", "exchange_rate": 45.570814 },
    "UZ": { "currency_code": "UZS", "currency_symbol": "лв", "exchange_rate": 13956.092746 },
    "VE": { "currency_code": "VES", "currency_symbol": "Bs.S", "exchange_rate": 75.168248 },
    "VN": { "currency_code": "VND", "currency_symbol": "₫", "exchange_rate": 27703.792978 },
    "VU": { "currency_code": "VUV", "currency_symbol": "VT", "exchange_rate": 133.284243 },
    "WS": { "currency_code": "WST", "currency_symbol": "WS$", "exchange_rate": 3.065135 },
    "XA": { "currency_code": "XAF", "currency_symbol": "FCFA", "exchange_rate": 657.237074 },
    "XC": { "currency_code": "XCD", "currency_symbol": "$", "exchange_rate": 2.924594 },
    "XO": { "currency_code": "XOF", "currency_symbol": "CFA", "exchange_rate": 657.237074 },
    "XP": { "currency_code": "XPF", "currency_symbol": "₣", "exchange_rate": 119.564613 },
    "YE": { "currency_code": "YER", "currency_symbol": "﷼", "exchange_rate": 265.755117 },
    "ZA": { "currency_code": "ZAR", "currency_symbol": "R", "exchange_rate": 20.069233 },
    "ZM": { "currency_code": "ZMW", "currency_symbol": "ZK", "exchange_rate": 30.288514 },
    "ZW": { "currency_code": "ZWL", "currency_symbol": "$", "exchange_rate": 347.838797 }
  }
}

export const EUROZONE_COUNTRIES = [
  "AT",
  "BE",
  "CY",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PT",
  "SK",
  "SI",
  "ES",
  "HR",
];

export const INTEGER_PRICE_CURRENCIES = [ // в отдельном массиве потому что потом бэк не будет отдавать такой инфы
  "BIF",
  "GNF",
  "IDR",
  "IRR",
  "JPY",
  "KHR",
  "KRW",
  "KZT",
  "LAK",
  "MGA",
  "MNT",
  "MWK",
  "PYG",
  "RWF",
  "SLL",
  "TJS",
  "TMT",
  "TZS",
  "UGX",
  "UZS",
  "VND",
  "XAF",
  "ZWL",
  "INR",
]

export const TEN_ROUNDED_CURRENCIES = [
  "JPY",
  "KPW",
  "KRW",
]