import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { MemoryRouter, } from 'react-router-dom';

import { PROTECTED_ROUTES, PUBLIC_ROUTES } from 'src/constants/routes';

import { RootState } from 'src/store/types';

import { QuizRouter } from './quiz-router'
import { LandingRouter } from './landing-router'

export const Routing: FC = () => {
  const startRoute = useSelector((state: RootState) => state.routing.startRoute) as string
  const landingRoutes = Object.values({ ...PROTECTED_ROUTES, ...PUBLIC_ROUTES });

  return (
    <MemoryRouter initialEntries={[startRoute]}>
      {!landingRoutes.includes(startRoute) && (
        <QuizRouter />
      )}
      <LandingRouter />
    </MemoryRouter>
  )
}
