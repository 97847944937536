import { validateApplePaySession } from '../utils/validate-applepay-session';

interface Options {
  country: string,
  currency: string,
  label: string,
  amount: string,
  requireEmail?: boolean,
}

interface Callbacks {
  paymentmethod?: (formData: any) => void,
}

class ApplePay {
  config: Options
  callbacks: Callbacks

  constructor(options: Options) {
    this.config = options;
    this.callbacks = {};
  }

  canMakePayment() {
    return Promise.resolve(ApplePaySession?.canMakePayments());
  }

  on(event: 'paymentmethod', callback: (formData: any) => void) {
    this.callbacks[event] = callback;
  }

  off(event: 'paymentmethod') {
    this.callbacks[event] = undefined;
  }

  begin() {
    const s = new ApplePaySession(4, {
      countryCode: this.config.country,
      currencyCode: this.config.currency,
      merchantCapabilities: ["supportsCredit", "supports3DS", "supportsDebit"],
      requiredBillingContactFields: ["postalAddress"],
      requiredShippingContactFields: this.config.requireEmail ? ["email"] : [],
      supportedNetworks: ["visa", "masterCard", "amex", "discover", "jcb"],
      total: { label: this.config.label, amount: this.config.amount }
    });
    s.onvalidatemerchant = (event: any) => {
      console.log(event)
      validateApplePaySession(event.validationURL)
        .then(r => {
          s.completeMerchantValidation(r);
        })
        .catch(() => {
          s.completeMerchantValidation({});
        });
    }
    s.onpaymentauthorized = (event: any) => {
      console.log('onpaymentauthorized', event)
      s.completePayment({ status: ApplePaySession.STATUS_SUCCESS });
      this.callbacks.paymentmethod?.({
        email: event.payment.shippingContact?.emailAddress,
        data: event.payment.token.paymentData.data,
        header: event.payment.token.paymentData.header,
        signature: event.payment.token.paymentData.signature,
        version: event.payment.token.paymentData.version,
      })
    }
    s.begin();
  }
};

export const useSolidgate = () => {
  return {
    ApplePay: function (options: Options) {
      return new ApplePay(options);
    }
  };
};
