import type { FC } from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Title, Text, Button } from '@web-solutions/core/ui-elements';

import classes from './style.module.scss';

interface NoteBlockProps {
  visible: boolean;
  title?: string;
  text?: string;
  icon?: string;
  onContinue: () => void;
}

const NoteBlock: FC<NoteBlockProps> = ({ visible, title, text, icon = '💡', onContinue }) => (
  <div className={classNames(classes.noteContainer, { [classes.visible]: visible })}>
    <div className={classes.note}>
      <span className={classes.icon}>
        {icon}
      </span>
      <Title level='h4' className={classes.title}>
        {title}
      </Title>
      <Text className={classes.text}>
        {text}
      </Text>
    </div>
    <div className={classes.buttonWrapper}>
      <Button
        type="submit"
        title={t('_continue')}
        onClick={onContinue}
      />
    </div>
  </div>
)

export default NoteBlock;
