import type { FC } from 'react'

import { TermsMenuMediator as CoreTermsMenuMediator, type TermsMenuMediatorProps as CoreTermsMenuMediatorProps } from '@web-solutions/core/containers/terms-menu/terms-menu-mediator';

interface TermsMenuMediatorProps extends Omit<CoreTermsMenuMediatorProps, 'showTermsAdditionalEffect'> { }

export const TermsMenuMediator: FC<TermsMenuMediatorProps> = (props) => (
  <CoreTermsMenuMediator
    theme='dark'
    {...props}
  />
)

