import { type FC, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Loader } from '@web-solutions/core/components/loader';
import { useQuizFirstStepAnalytics } from '@web-solutions/core/hooks/use-quiz-first-step-analytics';

import { selectFlow } from 'src/store/routing/selectors';
import { getPersonalizedData } from 'src/store/profile/selectors';

import { QUIZ, QUIZ_NAMES } from 'src/constants/routes';
import { QUIZ_SCREENS } from 'src/constants/screens';

import { QuizWrapper } from 'src/hocs/quiz-wrapper';
import { QuizTransition } from 'src/hocs/quiz-transition';
import { QuizStepWrapper } from 'src/hocs/quiz-step-wrapper';

import { QuizStep } from 'src/screens/quiz/quiz-step';
import { ProgressBar } from 'src/screens/quiz/progress-bar';
import { Footer } from 'src/screens/quiz/footer';
import { TermsMenuMediator } from 'src/screens/quiz/terms-menu-mediator';

import { useBirthChartData } from 'src//hooks/birth-chart';
// import { usePreloadLanding } from 'src/hooks/use-preload-landing'

export const QuizRouter: FC = () => {
  const flow = useSelector(selectFlow)
  const personalizedData = useSelector(getPersonalizedData);

  useBirthChartData(personalizedData);

  // usePreloadLanding({ loadStep: Math.max(flow.length - 3, 0) })
  useQuizFirstStepAnalytics(flow);

  return (
    <QuizWrapper>
      <ProgressBar />
      <TermsMenuMediator />
      <QuizTransition >
        {Object.values(QUIZ_NAMES).map((r, i) => {
          const Screen = QUIZ_SCREENS[r] || QuizStep
          const route = QUIZ[r];

          return (
            <Route key={i} path={route} element={
              <QuizStepWrapper >
                <Suspense fallback={<Loader />}>
                  <Screen currentPage={r} />
                  {r === flow?.[0] && (
                    <Footer />
                  )}
                </Suspense>
              </QuizStepWrapper>
            }>
            </Route>
          );
        })}
      </QuizTransition>
    </QuizWrapper>
  )

}