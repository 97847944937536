import type { AxiosResponse } from 'axios'

import { request as r } from './network';

import type {
  Headers,
  GetPublicKeyProps,
  GetPublicKeyResponse,
  GetPricesProps,
  CreateOrderProps,
  InitAlternativePaymentProps,
  CreateCheckoutProps,
  CreateCustomerProps,
  CreateSubscriptionProps,
  SubscribeOnOneClickProps,
  CreateApplePayOrderProps,
  CreatePayPalPurchaseProps,
  CancelSubscriptionProps,
  SubmitCancelSubscriptionProps,
  CreateOneClickPaymentProps,
  ChangePlanProps,
  SendAccessEmailProps,
  GiveProductProps,
  PauseSubscriptionProps
} from './types'

function request(headers = {}) {
  return r(`${process.env.REACT_APP_API_BILLING_URL || process.env.REACT_APP_API_BASE_URL}/payments`, headers);
}

const API = {
  getPublicKey(params: GetPublicKeyProps, headers?: Headers): Promise<AxiosResponse<GetPublicKeyResponse>> {
    return request(headers).get('/config', { params });
  },

  getPrices(params: GetPricesProps, headers?: Headers): Promise<any> {
    return request(headers).get('/products', { params });
  },

  createOrder(body: CreateOrderProps, headers?: Headers): Promise<any> {
    return request(headers).post('/orders', body);
  },

  initAlternativePayment(body: InitAlternativePaymentProps, headers?: Headers): Promise<any> {
    return request(headers).post(
      process.env.REACT_APP_SOLIDGATE_ALTERNATIVE_SUPPORTED
        ? '/orders/solid-alternative'
        : '/orders/paypal-via-solidgate',
      body,
    );
  },

  createCheckout(body: CreateCheckoutProps, headers?: Headers): Promise<any> {
    return request(headers).post('/checkouts', body);
  },

  createCustomer(body: CreateCustomerProps, headers?: Headers): Promise<any> {
    return request(headers).post('/customers', body);
  },

  getActualizedSubscriptions(headers?: Headers): Promise<any> {
    return request(headers).get('/subscriptions/actualized');
  },

  getAllSubscriptions(headers?: Headers): Promise<any> {
    return request(headers).get('/subscriptions?all=true');
  },

  createSubscription(body: CreateSubscriptionProps, headers?: Headers): Promise<any> {
    return request(headers).post('/subscriptions', body);
  },

  subscribeOnOneClick(body: SubscribeOnOneClickProps, headers?: Headers): Promise<any> {
    return request(headers).post('/subscriptions/create-new-subscription-by-card-token', body);
  },

  createApplePayOrder(body: CreateApplePayOrderProps, headers?: Headers): Promise<any> {
    return request(headers).post(body.price_id ? '/apple-pay' : '/one-time-payment/create-by-apple-pay', body)
      //@ts-ignore  
      .then(r => body.price_id ? r : r?.one_time_payment);
  },

  createPayPalPurchase(body: CreatePayPalPurchaseProps, headers?: Headers): Promise<any> {
    return request(headers).post('/one-time-payment/create-by-pay-pal', body);
  },

  cancelSubscription(body: CancelSubscriptionProps, headers?: Headers): Promise<any> {
    return request(headers).post('/subscriptions/request-cancel', body);
  },

  submitCancelSubscription(params: SubmitCancelSubscriptionProps, headers?: Headers): Promise<any> {
    return request(headers).get('/subscriptions/cancel', { params });
  },

  pauseSubscription(body: PauseSubscriptionProps, headers?: Headers): Promise<any> {
    return request(headers).post('/subscriptions/pause', body);
  },

  createOneClickPayment(body: CreateOneClickPaymentProps, headers?: Headers): Promise<any> {
    return request(headers).post('/one-time-payment', body);
  },

  getOneTimePurchases(headers?: Headers): Promise<any> {
    return request(headers).get('/one-time-payment');
  },

  getPaymentMethod(headers?: Headers): Promise<any> {
    return request(headers).get('/one-time-payment/get-payment-method');
  },

  changePlan(body: ChangePlanProps, headers?: Headers): Promise<any> {
    return request(headers).post('/subscriptions/change-plan', body);
  },

  sendAccessEmail(body: SendAccessEmailProps, headers?: Headers): Promise<any> {
    return request(headers).post('/subscriptions/send-access-mail', body);
  },

  giveProduct(body: GiveProductProps, headers?: Headers): Promise<any> {
    return request(headers).post('/one-time-payment/free', body);
  },
};

export default API;
