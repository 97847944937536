export function validateApplePaySession(validationUrl: string) {
  return fetch('https://api.abiens.app/v1/apple-pay/payment-session', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      validationUrl,
      initiativeContext: window.location.hostname,
    })
  })
    .then(r => r.json());
}

