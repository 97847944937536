import { ProductDetails } from "core/store/billing/selectors";

const appName = process.env.REACT_APP_TITLE;

const periods: Record<string, string> = {
  "P1D": "1 day",
  "P1W": "1 week",
  "P2W": "2 weeks",
  "P3W": "3 weeks",
  "P1M": "1 month",
  "P3M": "3 months",
  "P6M": "6 months",
  "P1Y": "1 year"
}

const getOneTimePaymentDesc = (s: string) => {
  let text = PRODUCTS[s]

  if (!text && s.includes('astrologer_consultation')) {
    text = PRODUCTS.astrologer_consultation
  }

  if (!text) {
    text = s
  }

  return text;
}

const PRODUCTS: Record<string, string> = {
  numerology_analysis: 'Numerology Analysis',
  palmistry_guide: 'Palmistry Guide',
  astrologer_consultation: 'Advisors Consultation',
  birth_chart: 'Birth Chart Report',
  birth_chart_hardcover: 'Birth Chart Hardcover Book',
  compatibility: 'Compatibility Report',
  numerology_analysis_palmistry_guide: 'Numerology Analysis + Palmistry Guide',
  compatibility_birth_chart_ultra: 'Compatibility Report + Birth Chart Report',
  'birth_chart+compatibility': 'Compatibility Report + Birth Chart Report',
  astrocartography: 'Astrocartography Report',
  palm_reading: 'Palm Reading',
}

export const createOrderDescription = (product: ProductDetails) => {
  if (product.isOneTimePurchase) {
    return `${appName} ${getOneTimePaymentDesc(product.id)}`;
  }

  if (product.isTrial) {
    return `${appName} ${product.trial_period_days}-days Subscription`;
  }

  return `${appName} ${periods[product.period]} Subscription`
}